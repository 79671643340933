<template>
  <div>

    <template v-if="order">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <b-card>

            <b-row>

              <!-- User Info: Left col -->
              <b-col
                cols="21"
                xl="6"
                class="d-flex justify-content-between flex-column"
              >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">

                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h4 class="mb-0">
                        Colis N°{{ order.id }}
                      </h4>
                      <span class="card-text">

                        <b-badge
                          v-if="order.status == 0"
                          variant="warning"
                          class="badge-glow"
                        >
                          En attente
                        </b-badge>
                        <b-badge
                          v-if="order.status == 1"
                          class="badge-glow"
                        >
                          En Pickup
                        </b-badge>
                        <b-badge
                          v-if="order.status == 2"
                          variant="info"
                          class="badge-glow"
                        >
                          Au depot
                        </b-badge>
                        <b-badge
                          v-if="order.status == 3"
                          variant="danger"
                          class="badge-glow"
                        >
                          en cours
                        </b-badge>
                        <b-badge
                          v-if="order.status == 4"
                          variant="success"
                          class="badge-glow"
                        >
                          Livré
                        </b-badge>
                        <b-badge
                          v-if="order.status == 6"
                          variant="success"
                          class="badge-glow"
                        >
                          Livré payé
                        </b-badge>
                        <b-badge
                          v-if="order.status == 5"
                          variant="danger"
                          class="badge-glow"
                        >
                          Retour au dépôt
                        </b-badge>
                        <b-badge
                          v-if="order.status == 7"
                          variant="danger"
                          class="badge-glow"
                        >
                          Retour au fournisseur
                        </b-badge>
                        <b-badge
                          v-if="order.status == 9"
                          variant="danger"
                          class="badge-glow"
                        >
                          Retour au fournisseur payé
                        </b-badge>
                        <b-badge
                          v-if="order.status == 8"
                          variant="success"
                          class="badge-glow"
                        >
                          Transfert
                        </b-badge>
                        <b-badge
                          v-if="order.status == 12"
                          variant="danger"
                          class="badge-glow"
                        >
                          annulé par admin
                        </b-badge>
                        <b-badge
                          v-if="order.status == 13"
                          variant="danger"
                          class="badge-glow"
                        >
                          Colis perdu
                        </b-badge>
                      </span>
                    </div>
                    <div
                      v-if="(order.status == 0 || order.status == 1) && user.role === 'admin'"
                      class="d-flex flex-wrap"
                    >
                      <b-button
                        variant="primary"
                        :to="{ name: 'order-edit', params: { id: id } }"
                      >
                        Modifier
                      </b-button>
                      <b-button
                        variant="outline-danger"
                        class="ml-1"
                        @click="deleteOrder(id)"
                      >
                        Supprimer
                      </b-button>
                    </div>
                    <!-- <b-button
                      v-if="user.role === 'admin' && (order.status == 7 || order.status == 9)"
                      variant="outline-danger"
                      class="ml-1"
                      @click="deliverAgain"
                    >
                      Re-livraison
                    </b-button> -->
                  </div>
                </div>

                <div class="d-flex align-items-center mt-2">
                  <div class="d-flex align-items-center mr-2">
                    <b-avatar
                      variant="light-primary"
                      rounded
                    >
                      <feather-icon
                        icon="CloudLightningIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        Fragile
                      </h5>
                      <small
                        v-if="order.is_fragile == true"
                      >OUI</small>
                      <small
                        v-if="order.is_fragile == false"
                      >NON</small>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mr-2">
                    <b-avatar
                      variant="light-primary"
                      rounded
                    >
                      <feather-icon
                        icon="DollarSignIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        Décharge
                      </h5>
                      <small
                        v-if="order.is_discharge == true"
                      >OUI</small>
                      <small
                        v-if="order.is_discharge == false"
                      >NON</small>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mr-2">
                    <b-avatar
                      variant="light-primary"
                      rounded
                    >
                      <feather-icon
                        icon="RepeatIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        Echange
                      </h5>
                      <small
                        v-if="order.is_an_exchange == true"
                      >OUI</small>
                      <small
                        v-if="order.is_an_exchange == false"
                      >NON</small>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center mt-2">
                  <div class="d-flex align-items-center mr-2">
                    <b-avatar
                      variant="light-primary"
                      rounded
                    >
                      <feather-icon
                        icon="DollarSignIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div
                      v-if="order.supplier != null"
                      class="ml-1"
                    >
                      <h5 class="mb-0">
                        Fournisseur
                      </h5>
                      <small>{{ order.supplier.username }}</small>
                    </div>
                  </div>

                  <div class="d-flex align-items-center">
                    <b-avatar
                      variant="light-success"
                      rounded
                    >
                      <feather-icon
                        icon="TrendingUpIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        Destinataire
                      </h5>
                      <small>{{ order.name }}</small>
                    </div>
                  </div>

                </div>

              </b-col>

              <!-- Right Col: Table -->
              <b-col
                cols="12"
                xl="6"
              >
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="HomeIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Depot</span>
                    </th>
                    <td class="pb-50">
                      {{ order.repository.name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="MapPinIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Gouvernorat:</span>
                    </th>
                    <td class="pb-50">
                      {{ order.governorate.name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="MapPinIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Délégation:</span>
                    </th>
                    <td
                      v-if="order.delegation != null"
                      class="pb-50 text-capitalize"
                    >
                      {{ order.delegation.name }}
                    </td>
                  </tr>
                  <!-- <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="MapPinIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Localité:</span>
                    </th>
                    <td
                      v-if="order.locality"
                      class="pb-50 text-capitalize"
                    >
                      {{ order.locality }}
                    </td>
                  </tr> -->
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="PhoneIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Tel</span>
                    </th>
                    <td class="pb-50">
                      {{ order.phone1 }} / {{ order.phone2 }}
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <feather-icon
                        icon="DollarSignIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Prix</span>
                    </th>
                    <td>
                      {{ order.price_ttc }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="AtSignIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Adresse:</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ order.address }}
                    </td>
                  </tr>
                  <!-- <tr v-if="order.bestway_barcode != null && (user.role === 'admin' || user.role === 'manager')">
                    <th class="pb-50">
                      <feather-icon
                        icon="AtSignIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Bestway Barcode:</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ order.bestway_barcode }}
                    </td>
                  </tr> -->
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="DollarSignIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Mode de paiement:</span>
                    </th>
                    <td
                      v-if="order.payment_method == 0"
                      class="pb-50 text-capitalize"
                    >
                      Espèce
                    </td>
                    <td
                      v-if="order.payment_method == 1"
                      class="pb-50 text-capitalize"
                    >
                      Par chèque
                    </td>
                  </tr>
                  <tr>
                    <th
                      v-if="order.weight"
                      class="pb-50"
                    >
                      <feather-icon
                        icon="DollarSignIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Poids volumétrique:</span>
                    </th>

                    <td
                      v-if="order.weight"
                      class="pb-50 text-capitalize"
                    >
                      {{ order.weight.weight }}
                    </td>
                  </tr>
                  <!-- <tr v-if="order.external_supplier_name && order.external_supplier_phone">
                    <th
                      class="pb-50"
                    >
                      <feather-icon
                        icon="DollarSignIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Fournisseur Externe: {{ order.external_supplier_name }} - {{ order.external_supplier_phone }}</span>
                    </th>
                  </tr> -->
                </table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <b-card
            no-body
            class="border-primary"
          >
            <b-card-header
              class="d-flex justify-content-between align-items-center pt-75 pb-25"
            >
              <h5 class="mb-0" />
              <b-badge variant="light-primary">
                Barre code / qrcode
              </b-badge>
            </b-card-header>

            <b-card-body
              v-if="order"
              class="p-2 d-flex justifiy-content-between"
            >
              <barcode :value="order.barcode.toString()" />
              <qrcode-vue
                class="mt-1 ml-2"
                :value="order.id.toString()"
                :size="size"
                level="H"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card>
            <h4 class="mb-2">
              Historique
            </h4>
            <app-timeline>
              <app-timeline-item
                v-for="item in order.histories"
                :key="item.id"
                :title="item.message"
                :subtitle="item.description"
                :time="formatFn(item.created_at)"
                :variant="color(item.message)"
              />
            </app-timeline>

          </b-card>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card>
            <h4 class="mb-2">
              Produits
            </h4>
            <b-table
              hover
              :items="items"
              :fields="fields"
            />
          </b-card>
          <b-card v-if="order.return_reason_histories.length !== 0">
            <h4 class="mb-2">
              Historique de retour
            </h4>
            <b-table
              hover
              :items="order.return_reason_histories"
              :fields="returnFields"
            />
          </b-card>
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BCardBody,
  BCardHeader,
  BTable,
} from 'bootstrap-vue'

import storeAuth from '@/store/store'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import VueBarcode from 'vue-barcode'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import QrcodeVue from 'qrcode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ShowOrder',
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    barcode: VueBarcode,
    BCardBody,
    BCardHeader,
    BTable,
    AppTimeline,
    AppTimelineItem,
    QrcodeVue,
  },
  data() {
    return {
      value: 'https://example.com',
      size: 80,
      order: '',
      fields: ['désignation', 'quantité'],
      returnFields: ['return_reason', 'created_at'],
      items: [],
      user: {},
      id: '',
      tableVariants: [
        'none',
        'primary',
        'secondary',
        'info',
        'danger',
        'warning',
        'success',
        'light',
        'dark',
      ],
    }
  },
  created() {
    this.user = storeAuth.state.user
    this.id = this.$route.params.id
    this.getOrder()
  },
  methods: {
    deliverAgain() {
      axios.put(`/api/orders/${this.id}/re-delivery/`)
      this.getOrder()
      this.showToast('success', 'top-center', 'Re-livraison activé')
    },
    deleteOrder(id) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.isLoading = true
        if (result.value) {
          axios
            .delete(`/api/orders/delete/${id}/`)
            .then(() => {
              this.isLoading = false
              this.$router.push('/orders/')

              setTimeout(() => {
                this.showToast('success', 'top-center', 'colis supprimé avec succés')
              }, 1000)
            })
            .catch(error => {
              this.isLoading = false
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.isLoading = false
          this.$swal({
            title: 'Fermer',
            text: 'colis Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    async getOrder() {
      await axios
        .get(`/api/orders/detail/${this.$route.params.id}`)
        .then(response => {
          this.order = response.data
        })
      if (this.order != null) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.order.products.length; i++) {
          const qte = this.order.products[i].quantity
          const { product } = this.order.products[i]

          this.addnewitem(qte, product)
        }
      }
    },
    color(status) {
      if (status === 'En attente') {
        return 'danger'
      }
      if (status === 'En pickup') {
        return 'warning'
      }
      if (status === 'Au dépôt') {
        return 'info'
      }
      if (status === 'En cours') {
        return 'warning'
      }
      if (status === 'Retour au dépôt') {
        return 'danger'
      }
      if (status === 'Retour au fournisseur') {
        return 'danger'
      }
      if (status === 'Colis annulé par admin') {
        return 'danger'
      }
      if (status === 'En transfert') {
        return 'info'
      }
      return 'success'
    },
    addnewitem(qte, nom) {
      this.items.push({
        désignation: nom,
        quantité: qte,
      })
    },

    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
